import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Button, Form, Badge } from "react-bootstrap";
import './PhotoGallery.css'; 

const PhotoGallery = ({ photos }) => {
  const [shuffledPhotos, setShuffledPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [voterName, setVoterName] = useState("");
  const [votingAllowed, setVotingAllowed] = useState(true);
  const [results, setResults] = useState([]);
  const [hasVoted, setHasVoted] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [nextDay, setNextDay] = useState(null);

  useEffect(() => {
    // Fonction de mélange de Fisher-Yates
    const shuffleArray = (array) => {
      let shuffledArray = [...array];
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    };

    // Mélanger les photos à l'initialisation
    setShuffledPhotos(shuffleArray(photos));

    // Récupérer la configuration (ex: deadline) depuis le backend
    axios
      .get(`${process.env.REACT_APP_API_URL}/config`)
      .then((response) => {
        const fetchedDeadline = new Date(response.data.deadline);
        setDeadline(fetchedDeadline);

        // Calculer le lendemain de la deadline
        const nextDayDate = new Date(fetchedDeadline);
        nextDayDate.setDate(fetchedDeadline.getDate() + 1);
        setNextDay(nextDayDate.toLocaleDateString());

        const today = new Date();
        if (today >= fetchedDeadline) {
          setVotingAllowed(false);
          fetchResults();
        }
      })
      .catch((err) => console.error("Erreur lors de la récupération de la configuration", err));
  }, [photos]);

  const handleSelectPhoto = (event, { index }) => {
    const selectedPhoto = shuffledPhotos[index];
    const alreadySelected = selectedPhotos.find(p => p.id === selectedPhoto.id);

    if (!alreadySelected) {
      if (selectedPhotos.length < 3) {
        setSelectedPhotos([...selectedPhotos, { ...selectedPhoto, order: selectedPhotos.length + 1 }]);
      }
    } else {
      setSelectedPhotos(selectedPhotos.filter(p => p.id !== selectedPhoto.id));
    }
  };

  const handleVote = () => {
    if (!voterName) {
      alert("Veuillez entrer votre nom avant de voter.");
      return;
    }

    if (selectedPhotos.length === 3) {
      const photoIds = selectedPhotos.map(photo => ({ id: photo.id, order: photo.order }));
      axios
        .post(`${process.env.REACT_APP_API_URL}/vote`, { voterName, photoIds })
        .then(() => {
          alert("Vos votes ont été soumis !");
          setHasVoted(true);
        })
        .catch(err => {
          if (err.response && err.response.status === 400) {
            alert(err.response.data);
          } else {
            console.error("Erreur lors de la soumission du vote", err);
          }
        });
    } else {
      alert("Vous devez choisir 3 photos.");
    }
  };

  const fetchResults = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/results`)
      .then((response) => setResults(response.data))
      .catch((err) => console.error("Erreur lors de la récupération des résultats", err));
  };

  return (
    <Container className="my-4">
      <h2 className="text-center mb-4">Votez pour vos 3 photos favorites</h2>
      {hasVoted ? (
        <div className="text-center my-5">
          <h3>Merci d'avoir voté !</h3>
          {nextDay && (
            <p>Rendez-vous le {nextDay} pour les résultats !</p>
          )}
        </div>
      ) : votingAllowed ? (
        <div>
          <Row>
            {shuffledPhotos.map((photo, index) => (
              <Col key={photo.id} xs={6} md={4} className="mb-4">
                <div 
                  onClick={(event) => handleSelectPhoto(event, { index })}
                  className={`photo-container ${selectedPhotos.some(p => p.id === photo.id) ? "border border-primary" : ""}`}
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <img src={photo.url} alt={photo.title} className="img-fluid" />
                  <p className="text-center">{photo.title}</p>
                  {selectedPhotos.some(p => p.id === photo.id) && (
                    <div className="order-indicator">
                      #{selectedPhotos.find(p => p.id === photo.id).order}
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
          <Row className="mt-4 justify-content-center">
            <Col xs="auto">
              <Form.Control
                type="text"
                placeholder="Votre nom"
                value={voterName}
                onChange={(e) => setVoterName(e.target.value)}
                className="mr-2"
              />
            </Col>
            <Col xs="auto">
              <Button className="btn btn-primary" onClick={handleVote}>
                Soumettre les votes
              </Button>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <h3 className="text-center my-4">Les 3 gagnants :</h3>
          <Row>
            {results.map((winner, index) => (
              <Col key={winner.id} xs={6} md={4} className="mb-4">
                <div className="photo-container" style={{ position: "relative" }}>
                  <img src={winner.photo.url} alt={winner.photo.title} className="img-fluid" />
                  <div className="order-indicator" style={{ position: "absolute", top: "10px", left: "10px" }}>
                    <Badge variant="success">{index + 1}</Badge>
                  </div>
                  <p className="text-center">{winner.photo.title}</p>
                  <small className="text-muted text-center d-block">Auteur : {winner.photo.author}</small>
                  <p className="text-center">Points : {winner.points}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </Container>
  );
};

export default PhotoGallery;
