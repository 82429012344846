import React, { useState, useEffect } from "react";
import axios from "axios";
import PhotoGallery from "./PhotoGallery";
import { Container, Navbar, Spinner } from "react-bootstrap";

const App = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

useEffect(() => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/photos`)
    .then((response) => {
      console.log(response.data); // Pour vérifier les données reçues
      setPhotos(response.data);
      setLoading(false);
    })
    .catch((err) => {
      console.error("Erreur lors de la récupération des photos", err);
      setLoading(false);
    });
}, []);

  return (
    <div>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">Concours Photo</Navbar.Brand>
        </Container>
      </Navbar>

      <Container>
        {loading ? (
          <div className="text-center my-5">
            <Spinner animation="border" variant="primary" />
            <p>Chargement des photos...</p>
          </div>
        ) : (
          <PhotoGallery photos={photos} />
        )}
      </Container>
    </div>
  );
};

export default App;
